@import '~bootstrap/scss/bootstrap.scss';

$width: 1024px;
$bg: #e5ebf2;/*rgb(75, 191, 107);*/
$headerbg: #455c74;
$fontColor: #495057;

$header-bg: #f6fafe;
$header-color: #333;

$menuitem-active-border: #41B3F4;
$menuitem-active-color: #333;
$menuitem-active-bg: #eee;

// $headerBg

/*
 @-webkit-keyframes loadingBg {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

@keyframes loadingBg {
    0%{background-position:0% 50%}
    50%{background-position:50% 50%}
    100%{background-position:120% 50%}
} */


*, *::after, *::before {
  box-sizing: border-box;
}

.app-loading {

  position:fixed;
  z-index: 99;
  width:100%;
  height: 100%;
  background: #fff;
}
.sideMenu {

  height: 100%;
  z-index: 9;

  color: #FFF;
  padding-bottom: 30px;
  min-height: 100%;
  background-color: $headerbg;

  ul {

    margin: 0;
    padding: 0;
    list-style: none;
    text-align: left;

    li {

        border-bottom: 3px solid #4f6883;

      a {

        text-decoration: none;
        color: #c3c9d8;
        font-weight: 600;
        text-transform: uppercase;
        display: block;
        font-size: 13px;
        padding: 13px 20px;
        transition: border-left .1s;
      }

      >a:hover, >a.active{
        transition: border-left .1s;
        color: #FFF;
        border-left: 3px solid #41B3F4;
      }

    }

    li.active, li:hover {

    }

    .submenu {
      background: #4f6883;
      margin: 0;
      padding: 0;

      li {
        border-top: 3px solid $headerbg;
        a {
          color: #c3c9d8;
        }

        a:hover, a.active {
          color: #FFF;
          border: none;
        }
      }

    }
  }

}
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: helvetica;
  background: $bg;
  font-family: 'Poppins', 'Open sans', sans-serif;
  color: $fontColor;
  font-size: 11px;
}

.clear {
  clear: both;
}


ul.header-menu {
  text-align:center;
  margin: 0;
  padding: 0;
  list-style: none;
  // min-height: 40px;
  // float: left;
  white-space: nowrap;
  background-color: $header-bg;

  ul li a.active, ul li:hover a {


  }

  li{



    line-height: 34px;
    margin: 0;
    display:inline-block;
    // border-right: 1px solid #e5ebf2;

    &:last-child {
      border: none;
    }

    a {

      padding: 0 15px;
      display: block;
      text-decoration: none;
      color: $header-color;
      // text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;
      border-bottom: 2px solid #eee;
      transition: border-bottom .2s;

      &.active {
        background-color: $menuitem-active-bg;
        color: $menuitem-active-color;
        border-bottom: 2px solid $menuitem-active-border;
        transition: border-bottom .2s
      }
    }

    &:hover a {
      background-color: $menuitem-active-bg;
      color: $menuitem-active-color;
      border-bottom: 2px solid $menuitem-active-border;
      transition: border-bottom .2s
    }
  }

}


.header-wrap {

  min-height: 40px;
  background: $header-bg;
  position: relative;
  // z-index:  1;
  // margin: 0 15px;
  padding-left: 2px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  // box-shadow: 0px 5px 4px -4px;
  position: relative;
  z-index: 10;
  // top: 0;




  .header-content {
    padding-right: 15px;
    margin: 0;
  }










  .user-profile {
    float: right;
    margin: 0;
    padding: 0;


    li {
      color: $header-color;
      list-style: none;
      font-size: 13px;
      position: relative;
      border: none;
      line-height: 40px;

      a {
       border-top:  none !important;
      }

      .user-profile-menu::before {
          content: " ";
          display: block;
          position: absolute;
          top: -3px;
          right: 14px;
          width: 20px;
          height: 20px;
          background: #FFF;

          transform: rotateZ(45deg) skew(10deg, 10deg);

        }
      .user-profile-menu {
        .sep {
          height: 1px;
          background: #CCC;
          padding: 0;
        }

        li {

          margin: 0 !important;
          padding: 2px 15px;
          display: block;
          line-height: 26px;
          white-space: nowrap;
          border: none;


          a {
            border: none;
            text-transform: none;
            color: $header-color;
            font-size: 13px;
            font-weight: 400;
          }

        }

        li:hover {
            background: #FAFAFA;
        }

        visibility: hidden;
        opacity: 0;
        margin:0;
        padding:0;
        border: 1px solid  rgba(0, 0, 0, 0.12);

        position: absolute;
        top: 100%;
        right: 0px;
        background: #FFF;
        z-index: 2;
        box-shadow: 0 0 7px -3px rgba(0, 0, 0, 0.5);

      }

      .user-profile-menu.open {
        visibility: visible;
        opacity: 1;
      }

      .user-profile-username {
        cursor: pointer;
        font-weight: bold;
        text-decoration: underline;
      }

      .user-profile-avatar {
        vertical-align: middle;
        border-radius: 100%;
        display: inline-block;
        background: #ccc;
        box-shadow: 0 0 5px -2px #4f6883;
        height: 50px;
        width: 50px;
        margin-left: 8px;
        overflow: hidden;

        img {
          vertical-align: top;
          width: 55px;
          height: auto;

        }
      }

    }

   .user-profile-notification  {

      font-weight: bold;
      margin-left: 5px;
      padding: 2px 3px;
      background: #1d3557;
      color: #FFF;
      border-radius: 4px;
      position: relative;


      &:hover .notification-list {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition: opacity .2s, visibility .2s, transform .2s;
      }


      .notification-list {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-20px);
        transition: opacity .2s .6s, visibility .2s .6s, transform .2s .6s;

        position: absolute;
        top: 100%;

        right: 0;
        background: #FFF;
        border: 1px solid #c3c9d8;
        border-radius: 2px;
        z-index: 1;
        margin: 0;
        margin-top: 10px;
        padding: 0;
        width: 340px;


        li {
          padding: 10px 15px;
          line-height: initial;
          font-size: 13px;
          font-weight: 400;
          clear: both;
          border-bottom: 1px solid #c3c9d8;
          transition: background .17s;
          &:hover, &.active {
            background: #f6fafe;
            font-weight: 400;
          }

          a {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-decoration: none;
            color: inherit;
          }

          .notification-text {
            display: flex;
            flex: 0.7;
          }

          .notification-time {
            text-align: right;
            display: flex;
            flex: 0.3;
          }
        }

      }
   }


  }

}


.body-wrap {
  margin: 0;
  padding: 30px 0;
  background: #EAF1F7;
  .body-content {
    width: $width;
    margin: auto;
  }

}

.footer-wrap {


  .footer-content {
    padding-bottom: 25px;
    margin: auto;
  }

}


input.invalid {
  border-color: #fc6262 !important;
}


.login-box {
  font-size:13px;
  padding: 25px;
  border: 1px solid #c3c9d8;
  border-radius: 5px;
  margin: auto;

  .c-form-row {
    clear: both;
  }

  .input-style {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-family: inherit;
    border: 1px solid #c3c9d8;
    border-radius: 4px;
    color: inherit;
    padding: 0 6px;
    box-shadow: 0px 1px 4px -4px inset;


  }

  .label-style {
    display: block;
    font-size: 13px;
  }
  .submit-style {
    float: right;
    border: 1px solid #ccc;
    width: 200px;
    height: 30px;
    font-family: inherit;
  }

}

.mainContent {
	padding:15px;
	background: $bg;
}
.btn-space {
	margin-right:3px
}
.cp {
	cursor: pointer;
}
ul {
	list-style: none
}
.appRootEl.loading {
	opacity:.2;
}
.appRootEl {
	opacity:1;
}
.loading-overlay {
	position:fixed;
	width:100%;
	height:100%;
	top:0;
	left:0;
	background: linear-gradient(90deg, #FFF, #FFF, #c3c9d8, #FFF, #FFF);
  background-size: 400% 100%;
  background-position: 100% 0% ;
	opacity:0;
	visibility:hidden;
	z-index:9999;
	transition: opacity .5s, visibility .5s, transform .4s;
  animation: loadingBg 1s linear .2s infinite;
	transform:scale(1.3);

	.text {
		opacity:0;
		visibility:hidden;
		transition: opacity .5s, visibility .5s;
		height:50px;
		position:absolute;
		top:0;
		left:0;
		bottom:0;
		right:0;
		margin:auto;
		font-size:20px;
		text-align:center;
	}
}
.loading-overlay.open {
	transform:scale(1);
	transition: opacity .7s, visibility .7s, transform .4s;
	opacity:.9;
	visibility:visible;

	.text {
		transition: opacity .5s, visibility .5s;
		opacity:.9;
		visibility:visible;
	}
}

.animateFlip {
  transition:  transform .3s;
}
.flipIcon {
  transform: rotateZ(180deg);
}
@keyframes animateRow {
  0% {
    max-height: 0px;
  }
  100% {
    max-height: 730px;
  }
}

.animateRow {
  animation-name: animateRow;
  animation-delay: 0.1s;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  max-height: 0px;
  overflow: hidden;
  border-bottom: 1px solid #c3c9d8;
  transition: max-height .3s, display .3s 0s ;
  background: #f6fafe;
}
.animateRow.hideRow {

  border-bottom: none;
}

.ui-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  visibility: hidden;
  opacity: 0;
  transition: visibility .3s, opacity .3s;
  z-index: 450;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;

}
.delete-task {
  .ui-overlay-content {
    height: 220px
  }

}
.return-task {
  .ui-overlay-content {
    height: 320px;
  }
}

.add-task {
  .ui-overlay-content {
    height: 220px;
    max-width: 50%
  }
}

.ui-overlay.open {

  visibility: visible;
  opacity: 1;
  transition: visibility .3s, opacity .3s;
}

.ui-overlay-content {
  box-shadow: 0 0 10px -3px rgba(0,0,0,.6);
  border-radius: 2px;
  background: rgba(255, 255, 255, 1);
  position: absolute;
  min-height: 260px;//260px
  padding: 40px 30px;
  margin: auto;
  text-align: center;

  &.custom-export {
    overflow-y: auto;
    height: 90%;
  }
}
.delete-overlay {
  height: 200px;
  .delete-buttons {
    margin-top: 20px;
    display: block;
  }
}

.drop-overlay .ui-overlay-content {
  height: 350px;
}

.deny-overlay-text {
  height: 160px;

}
.status-overlay-text {
  height: 360px;
}
.ui-overlay-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
}

.head-titles {
  padding: 0;
  // border: 1px solid #c3c9d8;
  border-bottom: 1px solid #ccc !important;
  border-radius: 2px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-weight: bold;
  background: #fff !important;


  &.center div {
    text-align: center;
    justify-content: center;
  }



  >div:last-child {
    border-right: none !important;
  }
  >div:first-child {

    border-left: none !important;
  }
}
.ctable {
  .row:nth-child(even) {
    background: rgba(0,0,0, 0.02);
  }
}

.ctable-row {
  transition: background .17s;

  >div {
    display: flex;
    align-items: center;
    word-wrap: break-word;
    padding: 10px;

    &.seperate {
      justify-content: space-between
    }

    &:last-child {
      text-align: right;
    }

    &:first-child {
      font-weight: 600;
    }

  }



  &:hover {
    background: rgba(195, 211, 228, .4);
    transition: background .17s;
  }




}






.stripped-rows:nth-child(even) {
  background: $bg;
}

.stripped-rows:nth-child(odd) {
  background: #FDFFFD;
}

.table-actionBtns {
  text-align: center;
}
.user-profile {
  float: right;
}

ul.history-list {
  list-style: none;
  margin: 0;
  margin-top: 20px;
  padding: 0;

  li {
    line-height: 34px;
    background: $bg;
    border-radius: 6px;
    padding: 5px 10px;
    margin-bottom: 15px;
    font-size: 14px;

    .history-sub {
      display: block;
      float: right;
      font-size: 13px;
      font-weight: 500;
    }
  }

}

.inline-search-input {

  padding: 3px 7px;
  border: 1px solid #f1faee;
  border-radius: 4px;
  font-family: inherit;
  font-size: 13px;
  margin-top: 5px;
  width: 100%;
  background: #FDFFFD;

}
.packageList {
  font-size: 14px;
}
.col-val {
  vertical-align: middle;
}
.items-table tbody tr {
  font-size: 14px;
  cursor: pointer;
}
.items-table tr:nth-child(even) {
 background: #FDFDFD;
}
.items-table tbody tr:hover {
  background: #FCFCFC;
}
.form-label-icon {
  font-size: 14px;
  float: left;
  height: 24px;
  margin-right: 10px;
}
.taskForm {
  label {
    display: block;
  }
}
.overlay-success {
  color: #28a745;
  margin-right: 10px;
  font-size: 14px;
}

.overlay-fail {
  color: #fc6262;
  margin-right: 10px;
  font-size: 14px;
}

ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}
ul.timeline > li {
    font-size: 14px;
    margin: 20px 0;
    padding-left: 20px;
}
ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}
.stripped-rows {
  font-size: 13px;
}
.font-exp {
  font-size: 13px;
  color: #444;
}

.status-id-1 {
    border-color: #6c757d;
    color: #6c757d;
}

.status-id-2, .status-id-manager {
  border-color: #17a2b8;
  color: #17a2b8;

}

.status-id-3 {
  border-color: #007bff;
  color: #007bff;

}
.status-id-4 {
  border-color: #dc3545;
  color: #dc3545;

}
.status-id-5 {
  border-color: #28a745;
  color: #28a745;


}


.sidebar {
  background: #1d3557;
  position: fixed;
  height: 100%;
}
.mainContainer {
  // position: fixed;
  // height: 100%;
  // overflow-y: auto;
}
.logo-title {
  display: flex;
  text-align: center;
  justify-content: center;;
  align-items: center;
  align-content: center;
  height: 50px;
  font-size: 16px;
  background: #FFF;
  color: #315c8f;
  font-weight: bold;
  img {
    height: 80%;
    margin-right: 15px;;
  }
}
.bg-container {
  position: relative;
  background: #fff;
  padding: 15px;
  min-height: 500px;
  padding-bottom: 40px;
  border: 1px solid #c3c9d8;
  border-radius: 4px;
  box-shadow: 0 0 6px -3px rgba(0,0,0, .6);
}
.ctable {
  font-size: 13px;
  color: $fontColor;
}
.task-status {
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  word-wrap: normal;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 2px 4px;
}
.cbutton {
  border: none;
  background: transparent;
}

.container-header {
  margin: -15px -15px 20px -15px;
  padding: 15px;
  background: #f6fafe;

  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom: 1px solid #c3c9d8;
  font-size: 20px;
  font-weight: 600;

  &.header-spacing {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  &.nmb {
    margin-bottom: 0;
  }

  &.bt-1 {
    margin-top:40px;
    border-top: 1px solid #c3c9d8;
    border-radius:0;
  }
}

.history-info {
  font-size: 13px;
  margin-top: 5px;
}

.account-page {
  .account-personal label {
    line-height: 33px;
    text-align: right;
    display: block;
  }
}
.action-btn {
  font-size: 15px;
  color: #4f6883;
}

.pagination {
  display: flex;
  margin: 0;
  padding: 0;
  //border: 1px solid #c3c9d8;



  li{
    cursor: pointer;
    margin: 0 2px;
    padding: 5px 13px;
    // border-left:1px solid #c3c9d8;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #666;
    font-size: 13px;
    font-weight: 600;


    &:first-child{
      border: none;
    }

    &:hover, &.active {
      &:not(.label) {
        color: #495057;
        font-weight: bold;
        background: #f6fafe;
      }
    }

    &.label {
      color: inherit;
    }

    &.disabled {
      cursor: not-allowed;
      color: #999;
    }

    a {
      text-decoration: none;
      color: inherit;
    }
  }




}

.act-row {
  border-bottom:1px dashed #ccc;
  padding:8px;
}
.label-danger {
  color: #fc6262;
  font-weight: 600;
}
.notification-wrapper {
  position: fixed;;
  bottom: 40px;
  right: 40px;
  color: #FFF;
  background: #315c8f;
  z-index:11;
  padding: 15px;
  border-radius: 6px;
  transform: translateX(500px);
  transition: transform .3s;
}
.notification-wrapper.active {
  transform: translateX(0);
  transition: transform .3s;
}

.flash-alert {
  overflow: hidden;
  max-height: 0px;
  transition: max-height .2s, transform .2s;
  transform: translateY(-200px);

  .flash-close-btn {
    float: right;
    cursor: pointer;
  }

  &.active {
    max-height: 200px;
    transition: max-height .2s .2s, transform .2s .2s;
    transform: translateY(0);
  }


}
.page-menu {
  margin:0 -15px;
  margin-bottom: 30px;
  background: #FFF;
  padding: 0 15px ;
  height: 40px;
  border-bottom: 2px solid #ccc;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  font-size: 13px;
  &.dashboard {
    height: auto;
    margin: -15px;
    background: #f6fafe;
    font-size: 16px;
    padding-top: 5px;
  }

  ul {
    margin:0;
    padding: 0;
    list-style: none;
    white-space: nowrap;
    li {
      height: 40px;
      display: inline-block;

      &:first-child {
        border-left: none;
      }




      a {
        text-transform: uppercase;
        text-decoration: none;

        font-weight: 600;
        display: block;
        line-height: 40px;
        color: #333;
        padding: 0 30px;

      }

      & a.active, &:hover a {

          color: #31b6fc;

      }


      &:first-child a {
        padding-left: 15px;
      }


    }



  }
}

h6 {
  color: #315c8f;
  font-weight: 600;
  font-size: 15px;

}

.msc {
  overflow-x: auto;
  flex-direction: column;
  max-width: 100%;
  display: flex;
  justify-content: center;
}

.geo-suggestions-wrapper {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left:0;
  top: 100%;
  border-radius: 4px;
  margin-top: 4px;
  border:1px solid #ccc;
  background: #fff;
  z-index: 99;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  transition: opacity .2s, visibility .2s;

  &.active {
    opacity: 1;
    visibility: visible;
    transition: opacity .2s, visibility .2s;
  }

  ul {
    margin:0;
    padding:0;
    list-style: none;

    li {
      padding: 10px;
      border-bottom: 1px solid #ccc;


      &:hover {
        background: #FDFDFD;
        cursor: pointer
      }
    }
  }

}


.c-tooltip-wrapper {
  position: relative;


  &:hover .c-tooltip {
    transition: opacity .2s, visibility .2s, transform .2s;
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }
}
.c-tooltip {
  visibility: hidden;
  opacity: 0;
  transform: translateX(20px);
  position: absolute;
  bottom: -100%;
  border-width: 1px;
  border-color: #ccc;
  z-index: 2;
  background: rgba(0,0,0, .8);
  border-radius: 2px;
  color: #fff;
  padding: 10px;
  white-space: nowrap;
  left: 0;
  right: 0;
  margin: auto;
  transition: opacity .2s, visibility .2s, transform .2s;

}

.clear-geo-input {
  position: absolute;
  right: 1px;
  bottom: 1px;
  padding: 2px;
  height: auto;
  margin-top: 4px;
  height: 32px;
  line-height: 30px;
  display: block;
  cursor: pointer;
  padding: 0 7px;
  border-radius: 2px;
  z-index: 8;
  background: #FFF;
  border-left: 1px solid #ced4da;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.mapPinSvg {
  top: -28px;
  left: -5px;
  background: transparent !important;
}
.dashboard-title {
  font-size: 24px;
  display: block;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 25px;
}
.single-stat-number {
  display: block;
  text-align: center;

  font-size: 25px;
}

.single-stat-label {
  text-align: center;
  display: block;
}

.dashboard-single-stat {
  border-right: 1px solid #ccc;

  &:last-child {
    border: none
  }
}

.activityRow {
  margin-top: 35px;
}

.stat-activity-wrap {
  display: flex;
  align-items: center;
}
.stat-activity-label {
  display: flex;
}
.dashboard-icon {
  font-size: 18px;
  margin-right: 6px;
}

.status-ball {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background:#888;


  &.status-5 {
    background: #28a745;
  }

  &.status-4 {
    background: #dc3545;
  }
}

.history-note {

}

.history-attachment {
  color: #007bff;
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
}
.rc-slider-mark {
  display: none;
}

.agent-list-profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  flex: 1;
}
.agent-list-photo {
  display: flex;
  // width: 40px;
  // height: 40px;
  border:1px solid #ccc;
  background: #fff;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  position: relative;
  text-align: center;

}
.agent-list-username {
  display: flex;
  flex-direction: column;
}
.col-profile-ct {
  align-items: center;
  display: flex;

}
.agent-profile-action {
  align-items: center;
  display: flex;
}
.user-status {
  width:14px;
  height: 14px;
  border-radius: 10px;
  overflow: hidden;
  background: #999;
  position: absolute;
  top: -1px;
  right: -1px;
  border:2px solid #FFF;
  text-indent: -99999px;


}

.user-status-4 {
  background-color: #dc3545;
  color: #dc3545;
}

.user-status-5 {
  background-color: #28a745;
  color: #28a745;
}
.user-status-0 {
  background-color: #999;
}



.col-md-2.sm-hide {
  justify-content: flex-end;
}

@media only screen and (max-width: 600px) {
  .ctable-row > div:last-child {
    border-right: none;
  }

  .ctable-row > div:first-child {
    border-left: none;
  }
  .ctable-row {
    margin-bottom: 20px;
  }
}


.agent-bubble {
  font-size: 13px;
  white-space: nowrap;
}
.task-single {
  background: #FDFDFD;
  padding: 10px;
  border:1px solid #DDD;
  border-radius: 2px;
  margin-bottom: 10px;
}

.history-thumbnail {

  height: 45px;
  margin-left: 15px;
  border-radius: 4px;
  box-shadow: 0 0 8px -4px #000;
  border: 1px solid #fff;

}

.address-book-link {
  line-height: 13px;
  font-size: 13px;
}

.items-found {
  display: flex;
  align-content: center;
  align-items: center;
  p {
    margin-bottom: 0
  }
}

.per-page-wrap {
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  flex: 1;
  align-items: center;

  .per-page-content {
    align-items: center;
    align-content: center;
    display: flex;
    p {
      font-weight: 600;
      color: #666;
      font-size: 13px;
    }
  }
  p {
    margin-bottom: 0;
  }

}
.per-page {
  margin-left: 10px;
  display: flex
}

.customPlan {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding-bottom: 15px;
  cursor: pointer;

  &.active {
    border-color: #999;

    h5 {
      border-color: #999;
    }

  }

  h5 {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    margin: 0 -15px 15px -15px;
    background: #F6FAFE;
    color: $fontColor;
    text-align: center;
    padding: 10px 0;
    border-bottom:1px solid #CCC;

  }

  .plan-features {
    margin: 0;
    list-style-type: circle
  }
}

.user-plan {
  margin-right: 10px;
}

.form-group.availability-check {
  position: relative;

  .availability-check-indicator-wrap {
    height: calc(1.5em + 0.75rem + 2px);
    position: absolute;
    right: 0;
    bottom: 0;
    margin: auto;




    .availability-check-indicator {
      opacity: 0;
      visibility: hidden;
      transition: transform 0.2s, opacity .2s, visibility 0.2s;
      color: #28a745;;
      top: 0;
      position: absolute;
      right: 7px;
      bottom: 0;
      margin: auto;
      width: 20px;
      height: 20px;
      border-width: 2px;

      &.show {
        opacity: 1;
        visibility: visible;
      }

    }


    .validity-indicator {
      opacity: 0;
      visibility: hidden;
      top: 0;
      position: absolute;
      right: 7px;
      bottom: 0;
      margin: auto;
      width: 20px;
      height: 20px;
      border-width: 2px;
      text-align:center;

      &.valid {
        color: #28a745;
        opacity: 1;
        visibility: visible;
      }

      &.invalid {
        color: #fc6262;
        opacity: 1;
        visibility: visible;
      }
    }
  }
}


.pm-wrapper {

}

.pm-sidebar {
  ul {
    margin: 0;
    padding: 0;
    border-right: 1px solid #ccc;
    margin-right: 30px;

    li {

      border-bottom: 1px solid #ccc;
      margin-left: -15px;

      &.active {
        font-weight: bold;
        text-decoration: underline;
        border-left: 3px solid #31b6fc;
      }

      &:last-child {
        border:none;
      }

      a {
        padding: 10px;
        outline: none;
        justify-content: space-between;
        align-content: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        color:$fontColor;
      }
    }
  }

}

.pm-content {
  padding: 15px;
  max-height: 700px;
  overflow-y: auto;

  .pm-test-single {
    // display: none;



    h5 {
      margin-bottom: 15px;
      b {
        float: right;
        margin-right: 20px;
        font-size: 14px;
      }
    }

    &.show {
      display: block;
    }

    .row {
      align-content: center;
      align-items: center;
      margin: 0;
      padding: 5px 15px;
    }

    label {
      font-weight: 600;
      margin-bottom: 0;
      margin-right: 10px;
    }

  }

}

.singleCheck {
  display:flex;
  flex-direction: "row";
  border:1px solid #ccc;
  padding: 15px;
  margin-bottom: 10px;

  .singleCheck-content {
    p {
      margin: 0;
    }
  }

  .singleCheck-status {
    display: flex;
    align-items: "center";
    padding-right: 20px;
    font-size: 18px;
  }
}


.battery-test {

}
.battery-wrapper {
  border:1px solid #ccc;
  display:flex;
  flex-direction: row;
}

.battery-step-col {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

}

.battery-row, .step-label {
  padding: 15px;
  border-bottom: 1px solid #ccc;
  text-align: center;
  min-height: 51px

}

.string-wrapper {
  display: block;
  margin-top: 30px;
}

.test-photos-wrapper {
  margin: 20px 0;
  display:flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .test-photos-single {
    display: flex;
    flex-basis: 48%;
    margin-top: 20px;
    position: relative;

    .photo-comment {
      width: 100%;
      bottom: 0;
      left: 0;
      background:rgba(0, 0, 0, 0.4);
      z-index: 66;
      position: absolute;
      border-radius: 4px;

      p {
        padding:10px 15px;
        margin: 0;
        color: #fff;
      }
    }

  


    img {
      width: 100%;
      border-radius: 4px;
    }

  }
}
.photo-gps{
  // width: 100%;
  // top: 0;
  // left: 0;
  background:rgba(0, 0, 0, 0.4);
  //z-index: 66;
  position: absolute;
  border-radius: 4px;

  p {
    padding:10px 15px;
    margin: 0;
    color: #fff;
  }
}


#planning_file {
  padding: 0;
}

.fposb {
  flex-direction: column;
  display: flex;
  padding-bottom: 10px;
  justify-items: flex-end;
  justify-content: flex-end;
}

.filters-container {
  border:1px solid #c3c9d8;
  border-radius: 6px;
  display: flex;
  margin: 15px;
  width: 100%;
  padding: 25px 0;

  &.vert {
    flex-direction: column;
    padding: 25px;
  }
}

.dashboard-row {
  margin-top: 30px;

  .single-chart {

    // border:1px solid #ddd;
    border-radius: 6px;
    padding:15px 0;
    background: #fcfcfc;

    .view-more {
      position: absolute;
      left: 0;
      font-size: 13px;
      bottom: 0;top: 0;
      margin: auto;
      text-transform: uppercase;
    }
    // box-shadow: 0 0 10px -8px #000;

    h3 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 10px;

      span {
        margin-bottom: 10px;
        padding: 10px 0;
        font-weight: 300;
        &.single-value {
          font-size: 26px;;
          font-weight: 400;
        }
      }
    }
  }

  h4, h3 {
    position: relative;
    text-align: center;

    .single-value {
      font-weight: bold;
    }

  }

  .table {
    width: 100%;
    margin-top: 25px;

    th:last-child {
      text-align: right;
    }
  }
}

.dashboard-page {
  margin-top: 15px;

  h2 {
    margin:25px 0;
    background: #e71722;
    color:#fff;
    padding: 10px 15px;
    border-radius: 4px;
    border:1px solid rgba(0,0,0, 0.05);
    display: flex;
    justify-content: space-between;

    .export-single {
      float: right;
      border: 2px solid #fff;
      background: inherit;
      color: #fff;
      font-size: 14px;
      border-radius: 4px;
    }
  }
}

.filter-btn {
  justify-content: flex-end;
  align-content: flex-end;
  align-items: flex-end;
  justify-items: flex-end;
  flex-direction: column;
  display: flex;
  padding-bottom: 1rem;

  button {
    display: flex
  }
}

.advanced-filters {

  opacity: 0;
  visibility: hidden;
  max-height: 0px;
  transition: visibility 0.2, opacity .2s, max-height 0.3s ;

  &.open {
    max-height: 800px;
    opacity: 1;
    visibility: visible;
  }
}

.task-details-col {
    justify-content: flex-end;
    text-align: right;
}


.task-plan-col {
  flex: 1;
  display: flex;
  flex-direction: column;


  .line {
    display:flex;
    flex-direction: row;
    border-bottom: 3px solid #ccc;
    justify-content: space-between;
    align-content: center;
    padding: 2px;

    &.task-ontime {
      border-color:  rgba(0, 255, 0, .8);
    }

    &.task-delay {
      border-color:  rgba(255, 0, 0, .8);

    }



    &:last-child {
      border-bottom: none;
    }

    .line-label {
      font-size: 12px;
      color: #555;
      text-transform: uppercase;
      font-weight: 700;
    }
    .line-value {
      color: #333;
      font-weight: 600;
    }

  }
}
.task-tests-col {
  flex-direction: column;
  justify-content: center;
  align-content: center;

  .task-count-anomalies {
    font-size: 13px;
    font-weight: 400;
  }

  span {
    width: 100%;
    display: block;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    color: #444;
  }
}


.account-firstname {
  font-size: 16px;
  display:block;
  margin-top: 5px;
}

.single-anomalie {
  margin-bottom: 15px;
  border-radius: 4px;
  padding: 10px 0;
  border:2px solid #ccc;
  &.resolved {
    border: 2px solid rgb(48, 209, 88);
  }

  .resolved-label {
    color: rgb(48, 209, 88);
  }
}

.task-action-btn {
  outline: none !important;
  display: flex;
  border: none;
  margin: 0;
  margin: 0px 5px;
  padding: 0;
  color: #666;
  font-weight: bold;
  background: transparent;
  font-size: 12px;

  height: 100%;
  vertical-align: middle;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  transition: color .3s;

  &:hover {
    color: #007bff;
    text-decoration: none;
    transition: color .3s;
  }

  &.danger:hover {
    color: rgba(255, 0, 0, 0.8);
  }
}

.custom-export {
  background: #fdfdfd;
  text-align: left;

  form {
    padding-bottom: 30px;
    .fields-wrapper {
      margin-top: 25px;
      display: flex;
      flex-direction: row;
    }
  }
  ul {
    flex:0.9;
    margin: 0;
    padding: 15px;
  }

}


.export-fields-select-all {
  color: #007bff;
  font-weight: 600;;
  font-size: 13px;;
  cursor: pointer;
  text-align:right;
  z-index: 99;
  display: block;
}


.more-dialog {
  position: relative;
  // opacity: 0;
  // visibility: hidden;

  &.open {
    ul {
      opacity :1;
      visibility: visible;

    }
  }

  ul {
    opacity: 0;
    visibility: hidden;
    margin: 0;
    padding: 0;
    position: absolute;
    background: #f6fafe;
    right: -0;
    z-index: 22;
    padding: 10px 0;
    border:1px solid #ccc;
    border-radius: 6px;;
    box-shadow: 0 0 9px -4px #000;
    border-top-right-radius: 0;

    li {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      background:#fff;
      word-wrap: normal;
      white-space: nowrap;
      border-bottom: 1px solid #ccc;
      padding: 8px 20px;
      font-size: 13px;
      font-weight: bold;
      cursor: pointer;

      &:first-child {
        border-top: 1px solid #ccc;

      }

      &:hover {

        background:#f6fafe;
      }

      .dialog-icon {
        width: 25px;
        margin-right: 10px;
      }

    }


  }

}

.custom-btn {
  display: flex;
  font-size: 16px;
  border:1px solid #007bff;
  background: #007bff;
  color: #fff;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-weight: 500;
  border-radius: 4px;
  padding: 3px 15px;
}
.site-plan-col-date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start !important;
}
.site-plan-col-action {
  font-weight: 600;
  width: 100%;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

}

.table-space-between {
  flex-direction:row;
  justify-content: space-between;
}

.form-control {
  background-color: rgb(252, 252, 252) !important;
  border-color: rgb(180, 180, 180);
  border-radius: 2px;
  font-weight: 500;
  color:rgb(60,60,60);
  height: 32px;
  padding: 0 5px;
  font-size: 13px;;
}

div.custom-select__value-container {
  font-size: 13px;
  padding: 0 5px !important;
}
.custom-select__menu {
  font-size: 13px;
}
div.custom-select__control, div.custom-select__control--menu-is-open {
  font-size: 13px;
  background-color: rgb(252, 252, 252) !important;
  border-color: rgb(180, 180, 180);
  border-radius: 2px;
  box-shadow: none !important;
  min-height: 0 !important;
  // height: 32px !important;
  padding: 0 0px !important;
  min-width: 70px ;


  .custom-select__single-value {
    color:rgb(60,60,60)
  }


  .custom-select__indicator {
    color:rgb(60,60,60);
    padding: 5px 7px;
  }
}

label {
  font-weight: 600;
  font-size: 13px;
}

.anomalies-status-col {
  flex-direction: row;
  span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;;
  }

  .status-unresolved {
    color: orange;
    margin-right: 4px;
  }

  .status-resolved {
    color: green;
    margin-right: 4px;
  }

}

.side-filters {
  border-right: 1px solid #dfdfdf;
  padding-right: 15px;
  margin-right: -15px;

  h4 {
    font-weight: 300;
    border-bottom: 1px solid #ddd;
  }

  .form-group {
    margin-bottom: 8px;
    label {
      font-size: 13px;
      margin-bottom: 5px;
    }
  }

  .side-bar-menu {
    margin: 0;
    padding: 15px 0;

    li {
      padding: 5px 8px;
      border-left: 4px solid $headerbg;
      background:#f6fafe;
      margin-bottom: 2px;

      a, button {
        display: block;
        color: $headerbg;
        text-align: right;
      }
    }
  }


}

.site-link {
  font-size: 13px;
  font-weight: 600;
  color: inherit;
}

.ui-overlay-content.anomalie-history, .ui-overlay-content.site-overlay {
  max-height: 90%;
  overflow-y: auto;
}
.ui-overlay-content.site-overlay {
  min-width: 400px;
}

.small-number-col {
  font-weight: 600;
  text-align: center;
  justify-content: center;
}

.custom-inline-form {
  justify-content: space-between;
}

.asset-bloc {
  h5 {
    padding: 0;
    margin-bottom: 10px;
  }
  margin-bottom: 25px;
  padding: 25px;
  background:rgba(0,0,0,0.02);
  border-radius: 4px;
  box-shadow: 0 0 8px 0px rgba(0,0,0,0.2);
}

.custom-list {
  margin:0;
  padding:0;

  li {
    form {
      width: 100%;
      display: flex;
      justify-content: space-between;
      background-color: #f6fafe;
      padding: 8px 15px;
      margin-bottom: 2px;
      overflow: hidden;
    }

    display: flex;
    justify-content: space-between;
    background-color: #f6fafe;
    padding: 8px 15px;
    margin-bottom: 2px;
    overflow: hidden;


    .buttons-col {
      flex-direction: row;
      display: flex;
      transition: transform 0.15s;
      transform: translateX(150px);
      button {

      }
    }


    &:hover .buttons-col, .buttons-col.show {
      transform: translateX(0px);
    }

  }
}

.dashboard-filters-container {
  display: none;

  &.open {
    display: block;
  }
}

.single-item-type {
  border:1px solid #eee;
  box-shadow: 0 0 6px -4px #000;
  border-radius: 4px;
  padding: 15px;
}

.row-select-mode {
  cursor: pointer;
}

.row-selected {
  border-left: 4px solid rgb(9, 188, 138);
}

.page-top-menu {

  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
  ul {
    margin:0;
    padding: 0;
    display: flex;
    flex-direction: row;
    li {
      font-weight: 600;
      padding: 5px 15px;
      cursor: pointer;
      &.selected {
        border: 1px solid #ccc;
        border-radius: 4px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
        background: #fafafa;
      }
    }
  }
}


.section-title {
  font-weight: 600;
}
.detail-pair {
  display: flex;
  border-bottom: 1px solid #dadada;
  padding:15px 0;
  flex-direction: row;
  align-items: center;

  .detail-key {
    display: flex;
    padding: 0 15px;
    color: #777;
    font-weight: bold;
    // display: block;
    font-size: 13px
  }
  .detail-value {
    display: flex;
    font-weight: 600;
    color: #333;
    font-size: 15px;
  }
}

.active-row {
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 0 10px -5px;
  // transform: scale(1.01);
  background: #fff;
}

.date-filters {
  flex-direction: row;
  display: flex;
  align-content: center;
  align-items: center;
  margin-right: 15px;
  b {
    font-size: 14px;
    margin-right: 10px;
  }

  div {
  }
}

.dashboard-filter-input {
  height: 32px;
  width: 110px;
  border:1px solid #b4b4b4;
  margin-left:1px;
  font-family: inherit;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  text-align: center;
  padding: 0 10px;
}
.filter-apply {
  margin-left: 10px;
  height: 32px;
  border-radius: 4px;
  color:#fff;
  background-color: #4f6883;
  cursor: pointer;
  border:none;
  font-size: 15px;
  display: block;
  width: 30px;
  border:1px solid #4f6883;
}

.select-menu {
  width: 130px;
  margin-right: 15px;;
}

.page-loading {
  position: absolute;
  z-index: 999;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,.8);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.15s, visibility 0.2s;

  &.active {
    background-color: rgba(255,255,255,.8);
    transition: opacity 0.15s, visibility 0.2s;
    opacity: 1;
    visibility: visible;
  }

}

.navigate-dashboard {
  margin: 15px 0;
  display: flex;
  flex-direction: row;
  font-size: 27px;

  &.right {
    text-align: right;
    justify-content: flex-end;
  }

  &.left {
    text-align: left;
    justify-content: flex-start;
  }

}

.page-menu {
  ul {
    margin: 0;
    padding: 0;;
  }
}


.header-top {
  color: #333;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 25px;

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    font-size: 22px;
    //color: #e71722;
    color: #0882b8;
    font-weight: bold;
    text-decoration: none;

    img {
      margin-right: 15px;
      max-height: 50px;
    }
  }
}

.top-menu {
  background-color: #f6fafe;
  position: sticky;
  z-index: 9;
  top: 0;
  box-shadow: 0 15px 8px -14px;
}

.import-overlay {
  h5 {
    text-align: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eee;
  }

  h4 {
    font-size: 15px;
    font-weight: bold;
    padding-bottom: 10px;
  }

  text-align: left;
  .sidemenu {
    background-color: $header-bg;
    ul {
      margin: 0;
      padding: 0;

      li {
        border-bottom: 1px solid $headerbg;
        display: block;
        padding: 10px 0;
        &.active {
          font-weight: bold;
        }
        a {
          display: block;
          color: inherit
        }
        &:last-child {
          border:none;
        }
      }
    }
  }
}
.hoverable {
  // overflow-x: hidden;
  .showOnHover {
    visibility: hidden;
    opacity: 0;
    transform: translateX(10px);
    transition: transform 0.2s, visibility 0.2s, opacity 0.2s
  }

  &:hover {
    .showOnHover {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
      transition: transform 0.2s, visibility 0.2s, opacity 0.2s
    }
  }
}
.custom-calendar-item {
  border: none !important;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  &.task-done {
    background-color: rgba(0, 120, 0, .8) !important;
  }

  &.task-delay {
    background-color: rgba(120, 0, 0, .8) !important;
  }
}

.namespace-menu {
  background-color: #f6fafe;
  margin: 0;
  padding: 0;
  align-self: center;

  &>li {

    margin: 5px 10px;
    display: inline-block;
    a {
      // border-bottom: 2px solid ;
      border-bottom-color: #ccc;
      padding: 5px;;
      display: block;
      text-decoration: none;
      color: #333;
      font-size: 15px;
      font-weight: 500;

      &.active, &:hover {
        border-bottom-color: #41B3F4;
        color: #41B3F4;
      }
    }


  }
}
.asset-item {
  border: 1px solid #eee;
  border-radius: 2px;
  background-color: #fff;
  margin: 10px 15px;
  padding: 15px;
}

.dashboard-box {
  padding: 15px;
  background-color: #fafafa;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // height: 100%;
  .dashboard-box-title {
    font-family: "Poppins";
    font-weight: bold;
    font-size: 18px;
  }
  .dashboard-box-value {
    background: #fff;
    border-radius: 4px;
    display: inline-block;
    padding: 5px 15px;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 17px;
    text-align: center;
  }

  .dashboard-percentage {
    font-weight: 500;
    font-size: 15px;
  }
}
.dashboard-shadow {
  box-shadow: 0 0 5px -3px;
}

.sticky-message {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
}

.tab-list {
  margin: 10px 0;
  span {
    text-decoration: underline;
    padding: 8px;
    font-weight: 500;
    cursor: pointer;
    &.active {
      color: $menuitem-active-border
    }
  }
}

.app-version-tag {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 4px;
  color: #FFF;
}


.has-children {

  .main-submenu {
    display: none;
    position: absolute;
    z-index: 1000;
    background: #fff;
    margin: 0;
    padding: 0;
    border: 1px solid #fff;
    box-shadow: 0 0 6px -5px;
    border-radius: 4px;

    li {
      padding: 0 15px;
      border-bottom: 1px solid #efefef;

      a {
        font-size: 13px;
        white-space: nowrap;
      }

      &:hover {
        background-color: $header-bg;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &:hover .main-submenu {
    display: block
  }
}
